import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { get } from 'lodash';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import {
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  TabbedForm,
  FormTab,
  RadioButtonGroupInput,
  TextInput,
  // DateInput,
  NumberInput,
  required,
  minValue,
  Toolbar,
  SaveButton,
  SelectInput,
  email,
  number,
  ReferenceManyField,
  Datagrid,
  FunctionField,
  DateField,
  ReferenceField,
  DeleteButton,
  EditButton,
  ChipField,
  FileInput,
  FileField,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import { withStyles } from '@material-ui/core/styles';

import PreviewNoteButton from './PreviewNoteButton';
import AddDocumentButton from './AddDocumentButton';
import AddNoteButton from './AddNoteButton';
import AddContractDiscountButton from './AddContractDiscountButton';
import AddContractChargeButton from './AddContractChargeButton';

import {
  ContractSummary,
  ContractNotes,
  ContractDocs,
  ContractDiscount,
  ContractSchedule,
  PdfIcon,
} from '../_app/icons';
import PaymentsSchedule from './PaymentsSchedule';
import BooleanInput from '../_common/components/BooleanInput';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { PLANS, SOURCES, NOTICE_PERIODS, INDUSTRIES } from '../_app/constants';

const validate = ({ from, to }) => {
  const errors = {};
  if (moment(from).isAfter(moment(to))) {
    errors.from = ['Start time must be before end time'];
  }
  return errors;
};
const STATUSES = {
  'active': 'green',
  'pending': 'orange',
  'expired': 'red',
};
const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
};

const NotePreview = ({ record }) => (<PreviewNoteButton id={record.id} />);

const ContractsEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
    <AddDocumentButton {...props} />
    <AddNoteButton {...props} />
    <AddContractDiscountButton {...props} />
    <AddContractChargeButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const ContractsForm = ({ formValues, create, ...props }) => (
  <TabbedForm {...props} validate={validate} redirect={basePath => basePath} toolbar={<ContractsEditToolbar />} defaultValue={{ shouldCreateMembership: true }}>
    <FormTab label="Summary" icon={<ContractSummary />}>
      <ReferenceInput
        label="Space"
        source="spaceId"
        reference="spaces"
        sort={{ field: 'name', order: 'ASC' }}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="plan"
        validate={[required()]}
        choices={PLANS}
      />
      <DateInput source="startDate" label="Start Date" validate={[required()]} inputProps={{ max: '2999-12-31' }} />
      <DateInput source="endDate" label="Earliest End Date" validate={[required()]} inputProps={{ max: '2999-12-31' }} />
      {get(formValues, 'spaceId') && (get(formValues, 'plan') === 'Resident' || get(formValues, 'plan') === 'Dedicated Desk') && [
        <ReferenceArrayInput
          label="Office Number"
          source="officeIds"
          reference="private_offices"
          filter={{ spaceId: get(formValues, 'spaceId') }}
        >
          <AutocompleteArrayInput optionText={choice => choice.number} />
        </ReferenceArrayInput>
      ]}
      {get(formValues, 'spaceId') && get(formValues, 'plan') && [

        // <TextInput source="name" label="First & Last Name" validate={[required()]} />,
        <TextInput source="firstName" label="First Name" validate={[required()]} />,
        <TextInput source="lastName" label="Last Name" validate={[required()]} />,
        <TextInput source="email" validate={[required(), email()]} />,
        <TextInput source="phone" label="Mobile Number" validate={[required(), number()]} />,
        <TextInput source="company" label="Company Name" validate={[required()]} />,
      ]}
      {get(formValues, 'spaceId') && get(formValues, 'plan') && get(formValues, 'plan') !== 'Resident' && [
        <RadioButtonGroupInput source="businessType" choices={[
          { id: 'Business', name: 'Business' },
          { id: 'Sole-trader', name: 'SoleTrader' },
        ]} />
      ]}
      {get(formValues, 'spaceId') && get(formValues, 'plan') && [
        <SelectInput
          source="industry"
          choices={INDUSTRIES}
          validate={[required()]}
        />,
        <TextInput source="agreementNumber" validate={[required()]} />,
      ]}
      {get(formValues, 'spaceId') && get(formValues, 'plan') && get(formValues, 'plan') !== 'Resident' && [
        <NumberInput
          source="desksNumber"
          validate={[required(), minValue(0)]}
          inputProps={{
            min: 0,
            max: get(formValues, 'plan') === 'Local' ? 10 : 20
          }}
          label={`No. of ${get(formValues, 'plan')}s`}
        />
      ]}
      {get(formValues, 'spaceId') && get(formValues, 'plan') && [
        <NumberInput
          source="deposit"
          validate={[required(), minValue(0)]}
          inputProps={{
            min: 0,
          }}
        />,
        <BooleanInput label="Deposit Paid" source="depositPaid" />,
        // <NumberInput
        //   source="monthlyFee"
        //   validate={[required(), minValue(0)]}
        //   inputProps={{
        //     min: 0,
        //   }}
        // />,

        <SelectInput
          source="source"
          choices={SOURCES}
        />,
        // <BooleanInput label="Expired" source="expired" defaultValue={false} />,
        <BooleanInput label="Rolling" source="rolling" defaultValue={true} />,
        (!get(formValues, 'rolling') && <DateInput source="leavingDate" label="Leave Date" validate={[required()]} inputProps={{ min: moment().format('YYYY-MM-DD'), max: '2999-12-31' }} />),
        <SelectInput
          source="noticePeriod"
          choices={NOTICE_PERIODS}
          defaultValue={2}
        />,
        (create &&
          <FileInput source="pdf" label="Contract Document (PDF)" accept="application/pdf" validate={[required()]}>
            <FileField source="src" title="title" />
          </FileInput>
        )

      ]}
    </FormTab>
    <FormTab label="Documents" icon={<ContractDocs />} path="documents">
      <ReferenceManyField
        addLabel={false}
        sort={{ field: 'createdAt', order: 'DESC' }}
        reference="documents"
        target="contractId"
      >
        <Datagrid>
          <FunctionField label="Download" render={record => record.pdf ? (<a href={record.pdf.src} target='blank'><PdfIcon /></a>) : (<b>N/A</b>)} />
          <FunctionField label="Name" render={record => get(record, 'pdf.title', '')} />
          <DateField source="createdAt" showTime />
          <ReferenceField allowEmpty source="creatorId" reference="admins" linkType={false}>
            <FunctionField render={user => `${user.firstName} ${user.lastName}`} />
          </ReferenceField>
          <FunctionField render={record => (<DeleteButton resource="documents" basePath="/documents" record={record} redirect={`/contracts/${record.contractId}/documents`} />)} />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
    <FormTab label="Notes" icon={<ContractNotes />} path="notes">
      <ReferenceManyField
        addLabel={false}
        sort={{ field: 'createdAt', order: 'DESC' }}
        reference="notes"
        target="contractId"
      >
        <Datagrid>
          <DateField source="createdAt" showTime />
          <ReferenceField allowEmpty source="creatorId" reference="admins" linkType={false}>
            <FunctionField render={user => `${user.firstName} ${user.lastName}`} />
          </ReferenceField>
          <NotePreview />
          <FunctionField render={record => (<DeleteButton resource="notes" basePath="/notes" record={record} redirect={`/contracts/${record.contractId}/notes`} />)} />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
    <FormTab label="Discounts" icon={<ContractDiscount />} path="discounts">
      <ReferenceManyField
        addLabel={false}
        sort={{ field: 'createdAt', order: 'DESC' }}
        reference="contract_discounts"
        target="contractId"
      >
        <Datagrid>
          <DateField source="createdAt" showTime />
          <ReferenceField allowEmpty source="creatorId" reference="admins" linkType={false}>
            <FunctionField render={user => `${user.firstName} ${user.lastName}`} />
          </ReferenceField>
          <DateField source="dateStart" label="Start" />
          <FunctionField label="Date end" render={({ duration, dateEnd }) => duration === 99 ? 'Forever' : moment(dateEnd).format('DD.MM.YYYY')} />
          <FunctionField label="Value" render={discount => discount.discountUnit === '£' ? `£${numeral(discount.discount).format('0,00.00')}` : `${numeral(discount.discount).format('0.00')}%`} />
          <FunctionField label="Status" render={discount => (<ChipField source='status' record={discount} style={{ color: '#fff', backgroundColor: STATUSES[discount.status] }} />)} />
          <FunctionField render={record => (<DeleteButton resource="contract_discounts" basePath="/notcontract_discountses" record={record} redirect={`/contracts/${record.contractId}/discounts`} />)} />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
    <FormTab label="Charges" icon={<ContractDiscount />} path="charges">
      <ReferenceManyField
        addLabel={false}
        sort={{ field: 'createdAt', order: 'DESC' }}
        reference="contract_charges"
        target="contractId"
      >
        <Datagrid>
          <DateField source="createdAt" showTime />
          <ReferenceField allowEmpty source="creatorId" reference="admins" linkType={false}>
            <FunctionField render={user => `${user.firstName} ${user.lastName}`} />
          </ReferenceField>
          <DateField source="dateStart" label="Start" />
          <DateField source="dateEnd" label="End" />
          <FunctionField label="Value" render={discount => discount.discountUnit === '£' ? `£${numeral(discount.discount).format('0,00.00')}` : `${numeral(discount.discount).format('0.00')}%`} />
          <FunctionField label="Status" render={discount => (<ChipField source='status' record={discount} style={{ color: '#fff', backgroundColor: STATUSES[discount.status] }} />)} />
          <FunctionField render={record => (<DeleteButton resource="contract_charges" basePath="/notcontract_discountses" record={record} redirect={`/contracts/${record.contractId}/charges`} />)} />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
    <FormTab label="Payment Schedule" icon={<ContractSchedule />} path="schedule">
      <PaymentsSchedule {...props} />
    </FormTab>
  </TabbedForm>
);

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = connect(mapStateToProps);

export default enhance(ContractsForm);
